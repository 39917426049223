<template>
  <div id="app">
    <!-- <TabbarView></TabbarView> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <div style="height: 5rem;"></div> -->
    <div
      v-if="!$util.flag"
      style="
        text-align: center;
        font-size: 3vw;
        color: #888888;
        position: absolute;
        width: 100%;
        bottom: 2px;
        z-index: 999;
      "
    >
      <div>本服务由江西省监狱管理局提供</div>
    </div>
  </div>
</template>

<script>
import VConsole from 'vconsole';
import CryptoJS from 'crypto-js';
export default {
  name: 'App',
  data() {
    return {
      showTabbar: false, // 是否显示底部导航栏
      active: 0, // 当前激活的导航项索引
    };
  },
  onLaunch: function () {
    const vConsole = new VConsole();
  },
  created() {
    // 当前为赣服通线上环境
    if (!this.$util.flag) {
      // 不走人脸本地拿token测试
      if (!this.$util.isTestFace) {
        let postData = {
          mobile: this.$util.encryptPassword('13800138002'),
          name: '小明测试',
          papersnumber: this.$util.encryptPassword('341601197508112768'),
          faceBase64Str: '111',
        };
        let data = {
          app_id: this.$util.appid,
          interface_id: 'jyglgftlfga',
          version: '1.0',
          charset: 'UTF-8',
          timestamp: '20240822',
          biz_content: JSON.stringify(postData),
          origin: '0',
          header: '',
        };
        this.$axios({
          url: this.$util.gftSignUrl,
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: this.formData(data),
        }).then((val) => {
          if (val.data.success) {
            data.sign = val.data.data.sign;
            this.$axios({
              url: this.$util.gftApiUrl,
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: this.formData(data),
            }).then((val) => {
              if (val.data.code == 200) {
                let obj = JSON.parse(val.data.data);
                if (obj.code == 0) {
                  this.$toast.success('人脸认证登录成功');
                  localStorage.setItem('userInfos', JSON.stringify(obj.data));
                  localStorage.setItem('token', obj.data.token);
                  this.$router.push('/home');
                } else {
                  this.$toast.fail(obj.msg || '登录失败');
                  this.$router.go(-1);
                }
              }
            });
          }
        });
      } else {
        hw.getUserByTicketAES({
          abilityCode: 'b6840fdacae2486ab864cae98031e2e6',
          organCode: 'd88fd372c0d94fb9acdd7310042d93d0',
          success: (res) => {
            // 成功的回调（返回加密用户信息，密钥请在该能力详情页面-相关下载中获取）
            let userInfo = null;
            userInfo = JSON.parse(
              this.decrypt(res.userdetail, 'gOuWYBjME1cVtc5S')
            );
            hw.faceRecognitionBackFace({
              abilityCode: '006c761d7a2f4641acee05a94266df40',
              organCode: 'd88fd372c0d94fb9acdd7310042d93d0',
              name: userInfo.name,
              idcard: userInfo.papersnumber,
              success: (res) => {
                // console.log(res, '成功');
                let postData = {
                  mobile: this.$util.encryptPassword(userInfo.mobile),
                  name: userInfo.name,
                  papersnumber: this.$util.encryptPassword(
                    userInfo.papersnumber
                  ),
                  faceBase64Str: res.data,
                };
                let data = {
                  app_id: this.$util.appid,
                  interface_id: 'jyglgftlfga',
                  version: '1.0',
                  charset: 'UTF-8',
                  timestamp: '20240822',
                  biz_content: JSON.stringify(postData),
                  origin: '0',
                  header: '',
                };
                this.$axios({
                  url: this.$util.gftSignUrl,
                  method: 'post',
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                  data: this.formData(data),
                }).then((val) => {
                  if (val.data.success) {
                    data.sign = val.data.data.sign;
                    this.$axios({
                      url: this.$util.gftApiUrl,
                      method: 'post',
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                      data: this.formData(data),
                    }).then((val) => {
                      if (val.data.code == 200) {
                        let obj = JSON.parse(val.data.data);
                        if (obj.code == 0) {
                          this.$toast.success('人脸认证登录成功');
                          localStorage.setItem(
                            'userInfos',
                            JSON.stringify(obj.data)
                          );
                          localStorage.setItem('token', obj.data.token);
                          this.$router.push('/home');
                        } else {
                          this.$toast.fail(obj.msg || '登录失败');
                          this.$router.go(-1);
                        }
                      }
                    });
                  }
                });
              },
              fail: (err) => {
                console.log(err, '失败');
                this.$toast.fail('认证失败');
                this.$router.go(-1);
              },
            });
          },
          fail: (err) => {
            // 失败的回调
            console.log(err);
          },
        });
      }
    }
    // 在这里设置需要显示底部导航栏的页面路径
    const showTabbarRoutes = ['/', '/search'];
    // 监听路由变化，根据当前页面路径判断是否显示底部导航栏
    this.$router.afterEach((to) => {
      this.showTabbar = showTabbarRoutes.includes(to.path);
    });
  },
  methods: {
    formData(data) {
      let params = new FormData(); // 创建一个新的 FormData 对象
      Object.keys(data).forEach((item) => {
        params.append(item, data[item]); // 将每个键值对添加到 FormData 对象中
      });
      return params; // 返回生成的 FormData 对象
    },
    /**
     * 能力提供解密方法
     * @param word 需要解密的数据
     * @param key
     * @return {string} 解密后的字符串
     */
    decrypt(word, key) {
      let keys = CryptoJS.enc.Utf8.parse(key);
      let decrypt = CryptoJS.AES.decrypt(
        CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(word)),
        keys,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return decrypt.toString(CryptoJS.enc.Utf8);
    },
  },
};
</script>

<style>
@import '@/static/css/common.css';
</style>
