import { getCscpBasicHxItemCode } from '@/api/admin/index'
const state = {
  dict: {}
}
const mutations = {
  SET_DICT: (state, params) => {
    state.dict[params.key] = params.value
  }
}
const actions = {
  getDict({ commit, state }, key) {
    return new Promise((resolve) => {
      if (state.dict[key]) {
        resolve(state.dict[key])
      } else {
        getCscpBasicHxItemCode({ dicCode: key }).then(res => {
          if (res.data.code === 'OK') {
            commit('SET_DICT', { key: key, value: res.data.data })
            resolve(res.data.data)
          }
        })
      }
    })
  }

}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
