export const globalConfig = {
  // 请求后台路径-线上
  requestUrl: './',
  // 请求后台路径-本地
  bdUrl: 'http://10.1.191.97:9000/', //'http://www.jxjyglj.gov.cn/', //'http://10.1.44.220:9000/',
  // ws请求地址-https
  xsWebSocketHostUrl: '59.63.208.244:20000/ws/qqhj/family/',
  // ws请求地址-http
  bdWebSocketHostUrl: '10.1.191.97:9000/ws/qqhj/family/',
};
