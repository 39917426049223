import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/assets/font/font.css';
import 'lib-flexible/flexible.js';
import router from './router/router';
import store from './store';
import VueResource from 'vue-resource';
import util from './libs/util';
import '@/static/css/global.css';
// import VConsole from 'vconsole';
import axios from 'axios';

Vue.use(Vant);
Vue.use(VueResource);

Vue.prototype.$util = util;
Vue.prototype.$axios = axios;
// const vConsole = new VConsole();

Vue.config.productionTip = false;
const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default vm;
